<template>
    <admin-table title="术后医嘱" @search="handleSearch" v-model="table" :loading="loading">
        <div slot="search">
            <el-form :inline="true">
                <el-form-item>
                    <el-radio-group v-model="search.radio" @change="handleSearch">
                        <el-radio style="margin-right:10px" :label="0">未下医嘱</el-radio>
                        <el-radio :label="1">已下医嘱</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="手术日期">
                    <el-date-picker
                        v-model="search.date"
                        type="date"
                        size="mini"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        style="width: 160px"
                        @change="handleSearch"
                        placeholder="手术日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="手术医生">
                    <select-doctor v-model="search.doctor" @change="handleSearch"/>
                </el-form-item>
            </el-form>
        </div>
        <el-table-column type="index" width="50" align="center"/>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :key="idx">
        </el-table-column>
        <el-table-column width="250" align="center" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button size="mini" @click="handleNotice(scope.$index, scope.row)">术后医嘱</el-button>
            </template>
        </el-table-column>
    </admin-table>
</template>
<script>
    import SelectDoctor from "@/components/base/selectDoctor";
    export default {
        components: {SelectDoctor},
        data() {
            return {
                loading: false,
                search: {
                    page: 1,
                    pageSize: 20,
                    radio: 0,
                    date: this.getDefaultDate(),
                    doctor: '',
                },
                table: {
                    search: [],
                    columns: [
                        {title: '手术日期', field: 'date', width: ''},
                        {title: '预约时间', field: 'time', width: ''},
                        {title: '患者姓名', field: 'name', width: ''},
                        {title: '联系电话', field: 'phone', width: ''},
                        {title: '手术医生', field: 'doctor', width: ''},
                        {title: '初步诊断', field: 'diagnosis', width: ''},
                    ],
                    data: [],
                    total: 0,
                },
            }
        },
        methods: {
            async getData(e) {
                const resp = await this.$http.get('/surgery/advice/', {params: e})
                this.table.data = resp.data.data.data
                this.table.total = resp.data.data.total
            },
            async handleDelete(idx, row) {
                this.$refs[`popover-${idx}`].doClose()
                const resp = await this.$http.delete(`/surgery/appoint/${row.id}`)
                if (resp.data.code == 200) this.$message.success("删除成功")
                this.getData()
            },
            handleEdit(idx, row) {
                this.$router.push(`/main/appoint/form?id=${row.id}`)
            },
            handleNotice(idx, row) {
                this.$router.push(`/main/advice/advice?id=${row.id}`)
            },
            handleSearch() {
                this.getData(this.search)
            },
            getDefaultDate() {
                let now = new Date()
                let defaultDate = now.format("yyyy-MM-dd")
                return defaultDate
            }
        },
        mounted() {
            this.getData(this.search)
        }
    }
</script>
<style lang="scss" scoped>
</style>
